import React from 'react'
import PropTypes from 'prop-types'

import Button from '../button'
import './serviceHero.scss'

const ServiceHero = ({
  title, imgSrc, iconSrc, iconDescription,
}) => {
  const heroStyle = {
    backgroundImage: `url(${imgSrc})`,
  }
  return (
    <div className='services-hero-container' style={heroStyle}>
      <div className='mobile-menu-placeholder' />
      <div className='page-container services-hero-content'>
        {iconSrc && <div className='service-icon'><img src={iconSrc} alt={iconDescription} /></div>}
        <h1>{title}</h1>
        <Button flashy to={`/free-quote?service=${title}`}>Get A Quote</Button>
      </div>
    </div>
  )
}

ServiceHero.propTypes = {
  title: PropTypes.string,
  imgSrc: PropTypes.string,
  iconSrc: PropTypes.string,
  iconDescription: PropTypes.string,
}

export default ServiceHero
